import React, { useState, useEffect, useRef, useMemo } from 'react';
import styles from './styles';
import { useTheme, styled } from '@mui/material/styles';
import { Box, Grid, Icon, Typography, useMediaQuery } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ArticleQuote from '../ArticleQuote';
import Anime from 'react-anime';
import { Link } from 'gatsby';
import useDimensions from '../../helpers/hooks/useDimensions';

const AnimationWrapper = React.memo(({ inline, children }) =>
	typeof window === 'undefined' ? null : inline ? (
		<Typography component={'h5'} className="text">
			{children}
		</Typography>
	) : (
		children
	)
);

const AnimationTypography = React.memo(({ inline, line, total, quote, children }) => {
	const theme = useTheme();
	const sm = useMediaQuery(theme.breakpoints.up('sm'));
	const md = useMediaQuery(theme.breakpoints.up('md'));
	const xl = useMediaQuery(theme.breakpoints.up('xl'));

	if (!line) return children;

	const maxViewportWidth = 210;
	const size = xl ? 60 : md ? 60 : sm ? 60 : 30;

	return (
		<Typography
			component={inline ? 'span' : 'h6'}
			sx={{
				color: line?.color,
				fontSize: `${
					// quote && total <= 30
					// 	? 4
					// 	: quote && total <= 30
					// 	? 4
					// 	: quote && total <= 100
					// 	? 4
					// 	: quote && total > 100
					// 	? 4
					// 	: !quote && total <= 30
					// 	? 6
					// 	: !quote && total <= 100
					// 	? 5
					// 	: total / size
					quote ? (sm ? 2 : 1.2) : total <= 30 ? (sm ? 4 : 2.5) : sm ? 2.5 : 1.5
				}rem`,
				fontWeight: line?.weight?.toString(),
				display: 'flex',
				justifyContent: 'center'
			}}
			className={inline ? (line?.fill === false ? 'line-stroke' : '') : 'text'}
		>
			{children}
		</Typography>
	);
});

AnimationTypography.whyDidYouRender = true;

const AnimationAnime = React.memo(({ text }) => {
	// const characters = useMemo(() => (text ?? '').replace(/\s/g, '\xA0').split(''), [text]);

	return (
		<Anime
			key={text}
			easing="easeOutExpo"
			transformY={[-100, 0]}
			opacity={[0, 1]}
			onExiting={{ opacity: [1, 0], easing: 'linear' }}
			duration={1400}
			delay={(el, i) => 30 * i}
		>
			{text}
		</Anime>
	);
});

AnimationAnime.whyDidYouRender = true;

const ArticleBottom = ({ color, lineOne, lineTwo, lineThree, lineFour, inline, quote, back }) => {
	const theme = useTheme();
	const lines = [lineOne, lineTwo, lineThree, lineFour]?.filter(x => !!x);
	const size = lines?.reduce((acc, { text }) => {
		const totalChars = (text ?? '').replace(/\s/g, '\xA0').split('')?.length;
		if (acc > totalChars) return acc;
		return totalChars;
	}, 0);
	const [$articleBottom, { windowHeight, bottom, top }] = useDimensions();
	const _visible = top >= 0 && bottom <= windowHeight;
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		if (!_visible) return;
		setVisible(true);
	}, [_visible]);

	const StyledLink = styled(props => <Link {...props} />)`
		color: ${lineOne?.color};
	`;

	return (
		<Box ref={$articleBottom} sx={{ backgroundColor: color }} css={styles(theme)}>
			<ArticleQuote quote={quote} color={lineOne?.color}>
				<Box className="bottom-text">
					{visible && (
						<AnimationWrapper inline={inline}>
							{lines?.map(line => (
								<AnimationTypography
									quote={quote}
									inline={inline}
									line={line}
									total={size}
								>
									<AnimationAnime text={line?.text} />
								</AnimationTypography>
							))}
						</AnimationWrapper>
					)}
				</Box>
			</ArticleQuote>
			<StyledLink to="/work" className="back-link">
				<Grid container justifyContent="center" alignItems="center">
					<KeyboardBackspaceIcon className="back-arrow" sx={{ fontSize: '2rem', opacity: 0.5 }} />
					<Typography
						className="back-text"
						component={'body1'}
						sx={`color: ${lineOne?.color} !important, opacity: 0.5`}
					>
						{back}
					</Typography>
				</Grid>
			</StyledLink>
		</Box>
	);
};

export default ArticleBottom;
