import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SpeakToUs from '../components/SpeakToUs';
import { useTheme } from '@mui/material/styles';

import ArticleHero from '../components/ArticleHero';
import ArticleText from '../components/ArticleText';
import ArticleAside from '../components/ArticleAside';
import ArticleImages from '../components/ArticleImages';
import ArticleBottom from '../components/ArticleBottom';
import SEO from '../components/SEO';

import { Grid, useMediaQuery } from '@mui/material';

// eslint-disable-next-line
export const ArticleTemplate = ({
	headingOne,
	headingTwo,
	headingOneColor,
	headingTwoColor,
	color,
	subheading,
	_headerImages,
	headerImages,
	images,
	_images,
	aside,
	bottom,
	speakToUs,
	html,
	id
}) => {
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down('md'));
	const medium = useMediaQuery(theme.breakpoints.down('lg'));

	// Sorry had to comment out this returns an empty array :(

	// const headerImages = Object.keys(_headerImages ?? {})?.map(key => _headerImages[key]);
	// const images = Object.keys(_images ?? {})?.map(key => _images[key]);

	return (
		<div style={{ overflowX: 'hidden' }}>
			<SEO
				title="Play Retail Ltd | Retail Design Agency | London"
				description="We’re Play, a strategic retail design agency who utilises technology to deliver experiential shopper experiences, disrupting the future of retail. "
				image={`${id}.jpg`}
			/>
			{/* {!!frontmatter?.image?.childImageSharp?.gatsbyImageData?.src && (
				<ArticleHero image={frontmatter?.image?.childImageSharp?.gatsbyImageData?.src} />
			)} */}
			<ArticleHero
				images={headerImages}
			/>
			<Grid
				sx={{
					padding: mobile
						? '0 2rem 2rem'
						: medium
						? '2rem 2rem 2rem 2rem'
						: '0 10rem 10rem'
				}}
				container
				direction={mobile ? 'column' : 'row'}
				wrap="nowrap"
			>
				<Grid sx={{ maxWidth: '60%' }} item>
					<ArticleText
						headingOne={headingOne}
						headingTwo={headingTwo}
						headingOneColor={headingOneColor}
						headingTwoColor={headingTwoColor}
						color={color}
						subheading={subheading}
						html={html}
					/>
				</Grid>
				<Grid sx={{ maxWidth: '40%', width: '100%' }} item>
					<ArticleAside
						title={aside?.title}
						color={aside?.color}
						font={aside?.font}
						services={aside?.services}
						circles={[aside?.circle1, aside?.circle2, aside?.circle3]}
					/>
				</Grid>
			</Grid>
			<ArticleImages
				images={images}
			/>
			<ArticleBottom {...bottom} />
			<SpeakToUs {...speakToUs} />
		</div>
	);
};

ArticleTemplate.propTypes = {
	title: PropTypes.string,
	helmet: PropTypes.object,
	headerImages: PropTypes.array,
	images: PropTypes.array,
};

const Article = ({ data }) => {
	const { markdownRemark: article } = data;

	return (
		<Layout>
			<ArticleTemplate
				id={article.id}
				html={article.html}
				frontmatter={article.frontmatter}
				headingOne={article.frontmatter.headingOne}
				headingTwo={article.frontmatter.headingTwo}
				headingOneColor={article.frontmatter.headingOneColor}
				headingTwoColor={article.frontmatter.headingTwoColor}
				color={article.frontmatter.color}
				subheading={article.frontmatter.subheading}
				headerImages={article.frontmatter.headerImages}
				images={article.frontmatter.images}
				aside={article.frontmatter.aside}
				bottom={article.frontmatter.bottom}
				speakToUs={article.frontmatter.speakToUs}
				title={article.frontmatter.heading}
			/>
		</Layout>
	);
};

Article.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.object,
		headerImages: PropTypes.array,
		images: PropTypes.array
	})
};

export default Article;

export const pageQuery = graphql`
	query ArticleByID($id: String!) {
		markdownRemark(id: { eq: $id }) {
			id
			html
			frontmatter {
				headingOne
				headingTwo
				headingOneColor
				headingTwoColor
				listHeading
				color
				subheading
				description
				date
				featuredimage {
					childImageSharp {
						gatsbyImageData(width: 2000, quality: 100)
					}
				}
				headerImages {
					image {
						childImageSharp {
							gatsbyImageData
						}
					}
				}
				aside {
					title
					color
					font
					services
					circle1 {
						color
						innerText
						outerText
					}
					circle2 {
						color
						innerText
						outerText
					}
					circle3 {
						color
						innerText
						outerText
					}
				}
				images {
					image {
						childImageSharp {
							gatsbyImageData
						}
					}
				}
				bottom {
					color
					font
					inline
					quote
					lineOne {
						text
						color
						fill
						size
						weight
					}
					lineTwo {
						text
						color
						fill
						size
						weight
					}
					back
				}
				speakToUs {
					title
					paragraph
					button
				}
				footer {
					email
					phone
					address
					lineOne
					lineTwo
					linkOne
					linkTwo
					copyright
				}
			}
		}
	}
`;
