import React from 'react';
import styles from './styles';
import { useTheme } from '@mui/material/styles';
import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material';

const ArticleAside = ({ title, color, font, services, circles }) => {
	const theme = useTheme();

	return (
		<Box sx={{ backgroundColor: color }} css={styles(theme)}>
			<Typography component={'h5'} sx={{ color: font }} className="box-title" pb={4}>
				{title}
			</Typography>
			{services?.map(service => {
				return (
					<Typography component={'h5'} sx={{ color: font }} className="box-item">
						{service}
					</Typography>
				);
			})}
			<Container className="box-circles">
				{circles?.map(circle => {
					return (
						circle &&
						<Grid sx={{ height: '300px' }} container direction="column" alignItems="center" alignContent="center" justifyContent="flex-start">
							<Box className="circle" sx={{ backgroundColor: circle?.color }}>
								<Typography sx={{fontSize: circle?.innerText.length > 5 ? '1.5rem' : '2rem', height: circle?.innerText.length > 5 ? '30px' : '40px'}} className="circle-inner">
									{circle?.innerText}
								</Typography>
							</Box>
							<Typography className="circle-outer">{circle?.outerText}</Typography>
						</Grid>
					);
				})}
			</Container>
		</Box>
	);
};

export default ArticleAside;
