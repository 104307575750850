import { useCallback, useContext, useLayoutEffect, useState } from 'react';
// import { RootElementContext } from '@helpers/contexts';
// import useResizeObserver from '@react-hook/resize-observer';

function getDimensionObject(node) {
	const rect = node?.getBoundingClientRect?.();

	return {
		width: rect?.width ?? null,
		height: rect?.height ?? null,
		top: !!rect && 'x' in rect ? rect?.y : rect?.top ?? null,
		left: !!rect && 'y' in rect ? rect?.x : rect?.left ?? null,
		x: !!rect && 'x' in rect ? rect?.x : rect?.left ?? null,
		y: !!rect && 'y' in rect ? rect?.y : rect?.top ?? null,
		right: (!!rect && 'x' in rect ? rect?.x : rect?.left) + rect?.width ?? null,
		bottom: (!!rect && 'y' in rect ? rect?.y : rect?.top) + rect?.height ?? null,
		offsetLeft: node?.offsetLeft ?? null,
		offsetTop: node?.offsetTop ?? null,
		offsetRight: node?.offsetLeft + node?.offsetWidth ?? null,
		offsetBottom: node?.offsetTop + node?.offsetHeight ?? null,
		offsetWidth: node?.offsetWidth,
		offsetHeight: node?.offsetHeight,
		windowHeight: window.innerHeight,
		windowWidth: window.innerWidth,
		scrollY: window.scrollY
	};
}

function useDimensions({ liveMeasure = true, flush = false } = {}) {
	// const $root = useContext(RootElementContext);
	const [flushing, setFlushing] = useState(false);
	const [dimensions, setDimensions] = useState({});
	const [node, setNode] = useState(null);

	const ref = useCallback(node => {
		setNode(node);
	}, []);

	const measure = () => {
		window.requestAnimationFrame(() => {
			if (flush) {
				setFlushing(true);
				setDimensions({});
				window.requestAnimationFrame(() => {
					setDimensions(getDimensionObject(node));
					setFlushing(false);
				});
			} else {
				setDimensions(getDimensionObject(node));
			}
		});
	};

	useLayoutEffect(() => {
		if (node) {
			measure();

			if (liveMeasure) {
				window.addEventListener('resize', measure);
				window.addEventListener('scroll', measure);

				return () => {
					window.removeEventListener('resize', measure);
					window.removeEventListener('scroll', measure);
				};
			}
		}
	}, [node, flush]);

	// useResizeObserver($root, measure);

	return [ref, dimensions, node, flushing];
}

export default useDimensions;
