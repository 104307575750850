import { css as styled } from '@emotion/react';

const styling = theme => styled`

	padding: ${theme.spacing(0, 20)};
    padding-bottom: ${theme.spacing(20)}; 

    ${theme.breakpoints.down('lg')} {
        padding: ${theme.spacing(0, 4)};
        padding-bottom: ${theme.spacing(10)};
    }

    ${theme.breakpoints.down('md')} {
        padding: ${theme.spacing(2, 0)};
    }

    .images-row-one,
    .images-row-two {
        flex-grow: 1;
    }

    .image-three img {
        max-height: 99%;
        height: 100%;
    }

    .image-four img {
        height: 99%;
    }

    .gatsby-image-wrapper {
        ${theme.breakpoints.down('sm')} {
            width: 100%;
            padding-bottom: ${theme.spacing(1)};
        }
    }

    img {
        max-width: 100%;
        width: 100%;
        height: 100%;

        ${theme.breakpoints.down('sm')} {
            object-fit: cover;
            height: 265px !important;
            width: 100%;
        }
    }

`;

export default styling;
