import { css as styled } from '@emotion/react';

const styling = theme => styled`

    position: relative;
    top: -80px;
    padding: ${theme.spacing(10)};
    max-width: 300px;
    max-height: 700px;
    min-height: 320px;
    margin: auto;
    opacity: 1;

    ${theme.breakpoints.between('md', 'lg')} {
        padding: ${theme.spacing(5)};
    }

    ${theme.breakpoints.down('md')} {
        top: 10px;
        margin: auto;
        margin-bottom: ${theme.spacing(15)};
    }

    ${theme.breakpoints.down('sm')} {
        padding: ${theme.spacing(5)};
    }

    .box-title {
        font-size: 2rem;
        font-weight: 600 !important;
    }

	.box-item {
        font-family: 'Comfortaa', sans-serif !important;
        font-weight: 600 !important;
        line-height: 1.5rem;
    }

    .box-circles {
        justify-content: space-around;
        position: absolute;
        left: 0;
        bottom: -240px;
        display: flex;
        padding: 0;

        ${theme.breakpoints.down('sm')} {
            justify-content: space-around;
            bottom: -250px;
        }

        ${theme.breakpoints.between('md', 'lg')} {
            justify-content: space-around;
            bottom: -235px;
        }

        ${theme.breakpoints.up('xl')} {
            justify-content: space-around;
        }
    }

    .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: relative;
        border-radius: 50%;
        line-height: 0;
        position: relative;
        min-width: 60px;
        max-width: 70px;
        width: 100%;
        min-height: 60px;
        max-height: 70px;
        height: 100%;
        padding: ${theme.spacing(2)};

        ${theme.breakpoints.down('sm')} {
            padding: ${theme.spacing(1)};
        }
    }

    .circle::after {
        content: '';
        display: block;
        padding-bottom: 100%;
    }

    .circle-inner {
        color: #fff;
        text-align: center;
        font-weight: 600 !important;

        ${theme.breakpoints.down('sm')} {
            position: relative;
            top: -5px;
        }
    }

    .circle-outer {
        color: #000;
        font-weight: 600 !important;
        text-align: center;
        max-width: 90px;
        display: flex;
        align-self: flex-end;
        padding-top: ${theme.spacing(2)};
        position: relative;
        align-self: center;
        left: 0;

        ${theme.breakpoints.down('sm')} {
            font-size: 0.9rem;
            left: 0;
        }
    }

`;

export default styling;