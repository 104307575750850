import { css as styled } from '@emotion/react';

const styling = theme => styled`

    .article {
        width: 80%;
        padding-top: ${theme.spacing(10)};

        ${theme.breakpoints.down('lg')} {
            padding-top: ${theme.spacing(4)};
        }

        ${theme.breakpoints.down(1000)} {
            padding-top: ${theme.spacing(10)};
        }

        ${theme.breakpoints.down('md')} {
            width: 100%;
            margin-top: 0;
            padding-top: ${theme.spacing(16)};
            padding-bottom: ${theme.spacing(10)};
        }

        ${theme.breakpoints.down(689)} {
            padding-top: ${theme.spacing(5)};
        }
    }

    .article-heading {
        font-size: 2.5rem;
        line-height: 50px;

        ${theme.breakpoints.down('md')} {
            font-size: 2.5rem;
        }
    }

    .article-subheading {
        font-size: 1.5rem;

        ${theme.breakpoints.down('md')} {
            padding-bottom: ${theme.spacing(2)};
        }
    }

    .article-content > h2 {
        font-size: 1.2rem;
    }

    .article-content > p {
        font-size: 1rem;
        font-family: 'Comfortaa', sans-serif !important;
        font-weight: 500 !important;
        line-height: 1.8rem;
        color: #000;
        padding-bottom: ${theme.spacing(2)};
    }

`;

export default styling;
