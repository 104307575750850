import { css as styled } from '@emotion/react';

const styling = theme => styled`

    width: 100vw;

	.hero {
        width: 100vw;
        height: 100vh;

        ${theme.breakpoints.down('lg')} {
            height: 60vh;
        }

        ${theme.breakpoints.down('md')} {
            height: 50vh;
        }

        @media (orientation: landscape) {
            ${theme.breakpoints.down(1000)} {
                height: 80vh;
            }
        }
    }

    .carousel {
        width: 100vw;
        height: 100vh;

        ${theme.breakpoints.down('lg')} {
            height: 60vh;
        }

        ${theme.breakpoints.down('md')} {
            height: 50vh;
        }

        @media (orientation: landscape) {
            ${theme.breakpoints.down(1000)} {
                height: 90vh;
            }
        }
    }

    .slide {
        min-height: 100vh;
    }

    .gatsby-image-wrapper, .slide-image {
        height: 100%;
        width: 100%;

        ${theme.breakpoints.down(689)} {
            height: 50%;
        }
    }

    .image {
        width: 100vw;
        height: 100vh;
        object-fit: cover;

        ${theme.breakpoints.down('lg')} {
            width: 100%;
            height: 70%;
        }

        @media (orientation: landscape) {
            ${theme.breakpoints.down(1000)} {
                height: 90vh;
            }
        }
    }

    .dot {
        width: 14px !important;
        height: 14px !important;
        position: relative;
        top: -14px;
        box-shadow: none !important;
        user-select: none;
        outline: none;
        -webkit-tap-highlight-color: transparent;
    }

    .control-arrow.control-prev::before {
        content: '';
        background: transparent;
        border-right: 3px solid transparent !important;
        border-left: 3px solid #fff !important;
        border-top: 3px solid transparent !important;
        border-bottom: 3px solid #fff !important;
        box-shadow: -2px 2px 2px rgb(0, 0, 0, 0.5);
        width: 20px;
        height: 20px;
        margin: 0 !important;
        transform: rotate(45deg);
        position: relative;
        user-select: none;
        left: 10px;
    }

    .control-arrow.control-prev {
        width: 56px;
        margin-left: 36px;
        padding: 0 !important;
        -webkit-tap-highlight-color: transparent;

        ${theme.breakpoints.down('sm')} {
            margin-left: 24px;
        }
    }

    .control-arrow.control-next {
        width: 56px;
        margin-right: 40px;
        padding: 0 !important;
        -webkit-tap-highlight-color: transparent;

        ${theme.breakpoints.down('sm')} {
            margin-right: 24px;
        }
    }

    .control-arrow.control-next::before {
        content: '';
        background: transparent
        border-right: 3px solid transparent !important;
        border-left: 3px solid #fff !important;
        border-top: 3px solid transparent !important;
        border-bottom: 3px solid #fff !important;
        box-shadow: -2px 2px 2px rgb(0, 0, 0, 0.5);
        width: 20px;
        height: 20px;
        margin: 0 !important;
        transform: rotate(-135deg);
        position: relative;
        user-select: none;
        right: 10px;
    }

    .control-arrow:hover {
        background: none !important;
    }

`;

export default styling;
