import { css as styled } from '@emotion/react';

const styling = theme => styled`\

    position: relative;

    .quote-box-upper,
    .quote-box-lower {
        position: relative;
        height: 100px;
        display: flex;
        align-items: center;

        ${theme.breakpoints.down('lg')} {
            width: 80%;
            margin: auto;
        }

        ${theme.breakpoints.down('sm')} {
            width: 80%;
            height: 50px;
            margin: auto;
        }
    }

    .quote-upper:after {
        content: "“";
        font-size: 20rem;
        width: 50px;
        height: 50px;
        position: relative;
        top: 10px;
        left: -20px;

        ${theme.breakpoints.down('md')} {
            font-size: 15rem;
            top: 20px;   
        }

        ${theme.breakpoints.down('sm')} {
            font-size: 10rem;
            top: -15px;   
        }
    }

    .quote-lower:after {
        content: "„";
        font-size: 20rem;
        position: absolute;
        width: 50px;
        height: 80px;
        top: -185px;
        right: 60px;

        ${theme.breakpoints.down('md')} {
            font-size: 15rem;
            top: -125px;   
            right: 30px;
        }

        ${theme.breakpoints.down('sm')} {
            font-size: 10rem;
            height: 20px;
            top: -138px;   
            right: 0;
        }
    }

    .quote-upper,
    .quote-lower {
        height: 50px;
        padding-bottom: ${theme.spacing(8)};
    }

    .line-upper,
    .line-lower {
        width: 85%;
        height: 2px;
        background-color: #fff;

        ${theme.breakpoints.down('md')} {
            width: 85%;
        }

        ${theme.breakpoints.down(500)} {
            width: 75%;
        }
    }

    .line-upper {
        margin-left: auto;
    }

    .line-lower {
        margin-right: auto;
    }

`;

export default styling;
