import React from "react"
import styles from "./styles"
import {useTheme} from "@mui/material/styles"
import {
	Box,
	Grid,
    useMediaQuery
} from "@mui/material"

import Image from '../Image';

const ArticleImages = ({ images }) => {
	const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))

	return (
		<Box css={styles(theme)}>
            { images &&
			<Grid className="images" container spacing={1}>
                <Grid className="images-row-one" item container direction={mobile ? "column" : "row"} flexWrap="nowrap" spacing={1.5}>
                    <Grid className="image-one" item><Image src={images[0]?.image} /></Grid>
                    <Grid className="image-two" item><Image src={images[1]?.image} /></Grid>
                </Grid>
                <Grid className="images-row-two" item container direction={mobile ? "column" : "row"} flexWrap="nowrap" spacing={1.5}>
                    <Grid className="image-three" item><Image src={images[2]?.image} /></Grid>
                    <Grid className="image-four" item> <Image src={images[3]?.image} /></Grid>
                </Grid>
            </Grid>
            }
        </Box>
	)
}

export default ArticleImages
