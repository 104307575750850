import { css as styled } from '@emotion/react';

const styling = theme => styled`

    padding: ${theme.spacing(20, 5)};

    ${theme.breakpoints.down('lg')} {
        padding: ${theme.spacing(10, 0)};
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    position: relative;

    ${theme.breakpoints.down('md')} {
        padding: ${theme.spacing(10, 0)};
    }

    ${theme.breakpoints.down('sm')} {
        padding: ${theme.spacing(8, 0)};
    }

    .bottom-text {
        padding: ${theme.spacing(2)};
        max-width: 80%;
        margin: auto;

        ${theme.breakpoints.down('lg')} {
            padding: ${theme.spacing(2, 5)};
            max-width: 100%;
        }

        ${theme.breakpoints.down(500)} {
            padding: ${theme.spacing(3)};
        }
    }

    .line-stroke {
        color: transparent;
        text-stroke: 2px #fff;
    }

    .text span {
        width: fit-content; 
        margin: auto;
    }

    .back-link {
        text-decoration: none;
        position: relative;
        top: 80px;

        ${theme.breakpoints.down('lg')} {
            top: 40px;
        }

        ${theme.breakpoints.down('md')} {
            top: 30px;
            font-size: 0.9rem !important;
        }
    }

    .back-text {
        text-decoration: underline;
        font-size: 1.2rem;
        opacity: 0.5;
        position: relative;
        top: 2px;
        left: 5px;

        ${theme.breakpoints.down('sm')} {
            font-size: 1rem;
        }
    }

    .back-arrow {
        position: relative;
        top: 2px;
    }
`;

export default styling;
