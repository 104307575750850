import React from 'react';
import styles from './styles';
import { useTheme } from '@mui/material/styles';
import { Container, Box } from '@mui/material';

const ArticleQuote = ({ quote, children, color }) => {
	const theme = useTheme();

	if (!quote) return children;

	return (
		<Container css={styles(theme)}>

			<Box className="quote-box-upper">
				<span className="quote-upper" style={{ color: color }}></span>
				<span className="line-upper" style={{ backgroundColor: color }}></span>
			</Box>

			{children}

			<Box className="quote-box-lower">
				<span className="line-lower" style={{ backgroundColor: color }}></span>
				<span className="quote-lower" style={{ color: color }}></span>
			</Box>
		</Container>
	);
};

export default ArticleQuote;
