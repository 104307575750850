import React from 'react';
import styles from './styles';
import { useTheme } from '@mui/material/styles';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { Box, Grid } from '@mui/material';

import Image from '../Image';

const ArticleHero = ({ images }) => {
	const theme = useTheme();

	console.log(images)

	// const newArray = images.filter(image => {
	// 	return image !== null
	// })

	// console.log(newArray)

	const slides = images?.map(image => <Image className="slide-image" src={image?.image} alt="Hero image" /> )

	return (
		<Box css={styles(theme)}>
			<Grid className="hero" container>
					<Carousel
						className="carousel"
						infiniteLoop
						autoPlay
						interval={3000}
						showStatus={false}
						showThumbs={false}
						swipeable={false}
					>
						{slides}
					</Carousel>
			</Grid>
		</Box>
	);
};

export default ArticleHero;
