import React from "react"
import styles from "./styles"
import {useTheme} from "@mui/material/styles"
import {
	Box,
	Grid,
	Typography,
} from "@mui/material"

const ArticleText = ({ headingOne, headingTwo, headingOneColor, headingTwoColor, color, subheading, html }) => {
	const theme = useTheme()

	return (
		<Box css={styles(theme)}>
			<Grid className="article" container direction="column">
				<Typography className="article-heading" component={"h1"} pb={2}><span style={{ color: headingOneColor }}>{headingOne}</span>{' '}<span style={{ color: headingTwoColor }}>{headingTwo}</span></Typography>
				<Typography className="article-subheading" component={"h2"} pb={4}>{subheading}</Typography>
				<div className="article-content" dangerouslySetInnerHTML={{ __html: html }}></div>
			</Grid>
		</Box>
	)
}

export default ArticleText
